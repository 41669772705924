import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ProductIcon = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 16px;
  background-color: #fff;
  margin-top: 4px;
  margin-left: 16px;
`;

const DropDownProductTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #484e62;
`;

const DropDownProductSubtitle = styled.span`
  font-size: 11px;
  padding-top: 2px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  text-align: left;
  color: #9bb0b5;
  display: flex;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 46px;
  justify-content: center;
`;

const LinkContainer = styled.div`
  display: flex;
`;

const DropDownItem = styled.a`
  text-decoration: none;
  display: flex;
  width: 100%;
  padding-top: 8px;
  border-radius: 5px;
  &:hover {
    background-color: #f7f9f9;
    cursor: pointer;
  }
`;

function AppLink({
  link,
  icon,
  productName,
  onClick,
  isActive,
  description,
}) {
  
  return (
    <DropDownItem 
      href={!isActive ? link : null}
      onClick={isActive ? () => onClick() : null}
      target="_blank"
    >
      <LinkContainer>
        <ProductIcon src={icon} />
        <TextContainer>
          <DropDownProductTitle>
            {productName}
          </DropDownProductTitle>
          <DropDownProductSubtitle>{description}</DropDownProductSubtitle>
        </TextContainer>
      </LinkContainer>
    </DropDownItem>
  );
}

AppLink.defaultProps = {
  link: null,
  icon: null,
  productName: null,
  isActive: true,
  description: null,
};

AppLink.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.string,
  productName: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isActive: PropTypes.bool,
  description: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default AppLink;
